<template>
  <div class="ip-restriction-page">
    <div class="page-content">
      <v-row no-gutters class="page-content-header">
        <v-col cols=12 class="text-center">
        <!-- <label class="title pa-3 ma-0 text-center white--text text-capitalize d-block full-width font-weight-bold">地区限制</label> -->
        <router-link :to="{ name: routeName.HOME, params:{lang: this.$route.params.lang}}">
          <img src="/static/svg/bb88_logo_w_sponsor.svg" alt width="215" height="35" style="float:center;"/>
        </router-link>
        </v-col>
      </v-row>
      <v-row no-gutters class="page-content-body">
        
        <v-col cols="12" class="text-center">
          <!-- <div class="mb-2">
            <p class="font-weight-bold subtitle-1 mb-1">地区限制</p>
            <p class="body-2">抱歉！您所在地区受到限制，无法正常游览我们的网站哦。若有不便之处，请多多原谅。若您有任何疑问，请联系我们的在线客服。 或发邮件至 info@BABU88.com 或联系在线客服</p>
          </div> -->
          <div>
            <img class="block-ip-icon" src="/static/svg/blockIP.svg" alt style="float:center;"/>
          </div>
          <div class="mt-5 mb-2">
            <p class="font-weight-bold subtitle-1 mb-1">Located Restricted Area</p>
            <p class="body-2">
              The page that you are trying to access has been restricted in this location. We apologize for any inconvenience that this may have caused. Should you have any inquiries, please contact
              our customer service centre at info@BABU88.com or Contact Customer Service
            </p>
          </div>
          <v-row class="mt-5 mb-2">
            <v-col cols=12>
<!--            <app-button class="error-page-button" :maxWidth="200" :title="$t(`label.liveChat`)" :action="openLiveChat"></app-button>-->
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" v-show="false">
            <v-col cols="auto">
              <img class="footer-icon" src="/static/svg/btm-fb.svg" alt/>
            </v-col>
              <v-col cols="auto">
              <img class="footer-icon" src="/static/svg/btm-insta.svg" alt/>
              </v-col>
              <v-col cols="auto">
              <img class="footer-icon" src="/static/svg/btm-twitter.svg" alt/>
              </v-col>
              <v-col cols="auto">
              <img class="footer-icon" src="/static/svg/btm-yt.svg" alt/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'errorIpRestriction',
  methods: {
    openLiveChat() {
      window.open('https://direct.lc.chat/13183530/', 'Live Chat', 'width=350,height=600')
    },
    goHome(){
      this.$router.push({
        name: ROUTE_NAME.HOME,
        lang: this.$route.params.lang
      })
    }
  }
}
</script>
<style lang="scss">
.ip-restriction-page {
  .page-content {
    position: absolute;
    max-width: 500px;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .page-content-header {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      background-color: #fff;
      position: relative;
      margin-bottom: 20px;
    }
    .page-content-body {
      padding: 20px;
      background-color: #fff;
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
    .block-ip-icon{
      width: 200px;
      height: 200px;
    }
  }
  .error-page-button{
    width: 200px !important;
  }
}

@media (max-width: 599px) {
  .ip-restriction-page {
    .page-content {
      .page-content-body {
        padding: 14px;
        background-color: #fff;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
      .block-ip-icon{
        width: 170px;
        height: 170px;
      }
    }
  }
}
</style>
